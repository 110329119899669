import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const CardGroup = makeShortcode("CardGroup");
const MiniCard = makeShortcode("MiniCard");
const Row = makeShortcode("Row");
const Title = makeShortcode("Title");
const Column = makeShortcode("Column");
const Aside = makeShortcode("Aside");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
    </PageDescription>
    <h2>{`Magnus Code Industrial Automation`}</h2>
    <p>{`Our automation solutions allows you to maximize productivity and enhance your organisation’s workflow.
Experts in system automation design and real time data monitoring. Have all your data, stream in real time to user dashboard. Monitor system usage, track performance, real time alerts, and more.`}</p>
    <h3>{`Some of our key industrial automation solutions includes:`}</h3>
    <CardGroup mdxType="CardGroup">
  <MiniCard title="Internet of Things (IoT)" mdxType="MiniCard">
        <img {...{
          "src": "/3f49bf955f9f262c220a3f73d222f1d3/iot--platform.svg",
          "alt": "IOT Icon"
        }}></img>
  </MiniCard>
  <MiniCard title="Supervisory Control & Data Acquisition (SCADA)" mdxType="MiniCard">
        <img {...{
          "src": "/d6ed05687f93ec86e5d82f9eb01c897d/analytics--reference.svg",
          "alt": "Analytics icon"
        }}></img>
  </MiniCard>
  <MiniCard title="Computerised Maintenance Management System (CMS)" mdxType="MiniCard">
        <img {...{
          "src": "/48003b0870d02f3b4b0827ee23f26aa1/calendar--tools.svg",
          "alt": "Calendar icon"
        }}></img>
  </MiniCard>
  <MiniCard title="Role Based Access Control" mdxType="MiniCard">
        <img {...{
          "src": "/2a12a97ed97a60ae4ad0ff4bdfc5ba44/password.svg",
          "alt": "Password icon"
        }}></img>
  </MiniCard>
    </CardGroup>
    <Row className="mt-4" mdxType="Row"></Row>
    <Title mdxType="Title">Control system design - PLC with IoT Hub</Title>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB31qiNki//8QAGRAAAwADAAAAAAAAAAAAAAAAAAIDARAi/9oACAEBAAEFAhKdF5NQxDX/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBAMAAAAAAAAAAAAAAAABAAIQIRESQf/aAAgBAQAGPwJODj2oGrsIXcf/xAAZEAACAwEAAAAAAAAAAAAAAAABEQAQITH/2gAIAQEAAT8hmB2FoJ4MEGJ6ZVf/2gAMAwEAAgADAAAAEK8P/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAQACAwEAAAAAAAAAAAAAAREAIRAxUXH/2gAIAQEAAT8QwTtsBGSzgIKCKrup58w+IhejCa81x//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fcf92427fafe30508e35ccca891ee3ad/5f1c3/simatic-t-cpus.webp 288w", "/static/fcf92427fafe30508e35ccca891ee3ad/c4eb6/simatic-t-cpus.webp 576w", "/static/fcf92427fafe30508e35ccca891ee3ad/c2ad5/simatic-t-cpus.webp 1152w", "/static/fcf92427fafe30508e35ccca891ee3ad/8de58/simatic-t-cpus.webp 1728w", "/static/fcf92427fafe30508e35ccca891ee3ad/a2d2e/simatic-t-cpus.webp 2304w", "/static/fcf92427fafe30508e35ccca891ee3ad/e3ad8/simatic-t-cpus.webp 3840w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fcf92427fafe30508e35ccca891ee3ad/58ca1/simatic-t-cpus.jpg 288w", "/static/fcf92427fafe30508e35ccca891ee3ad/7fee0/simatic-t-cpus.jpg 576w", "/static/fcf92427fafe30508e35ccca891ee3ad/914fe/simatic-t-cpus.jpg 1152w", "/static/fcf92427fafe30508e35ccca891ee3ad/1889a/simatic-t-cpus.jpg 1728w", "/static/fcf92427fafe30508e35ccca891ee3ad/a9b74/simatic-t-cpus.jpg 2304w", "/static/fcf92427fafe30508e35ccca891ee3ad/e4542/simatic-t-cpus.jpg 3840w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fcf92427fafe30508e35ccca891ee3ad/914fe/simatic-t-cpus.jpg",
              "alt": "PLC Image",
              "title": "PLC Image",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside aria-label="Example aside" mdxType="Aside">
          <p><strong parentName="p">{`Get real time sensor data.`}</strong></p>
          <p>{`Get all sensor data to a centralized location, available via `}<strong parentName="p">{`Magnus IOT Edge`}</strong>{` with role based access control.`}</p>
        </Aside>
      </Column>
    </Row>
    <h2>{`Through our IoT solutions, we can create personalized analytics for your organization.`}</h2>
    <p>{`Our real time remote monitoring of processes in your workflow will facilitate a better planning and reduce interruption thus increasing productivity and add value to your work.`}</p>
    <p>{`By collecting data from SCADA network, we can create organization specific remote application to enable monitoring of processes from any computer or mobile devices.`}</p>
    <p>{`We can integrate real time data collection and trends with Computerised Maintenance Management System to help your organization tackle any issues before they even appear. Our personalised software will also let your organisation create your very own planned maintenance system, bringing your organization to the next level.`}</p>
    <Row className="resource-card-group mt-4 " mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Talk to our team" title="Contact Us" aspectRatio="2:1" actionIcon="arrowRight" href="/contact" color="light" className="blue-card" mdxType="ResourceCard"></ResourceCard>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      